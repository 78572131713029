<template>
  <v-container fluid>
    <h2>Wettkampfbericht nach dem Wettkampf</h2>
    <v-row v-if="e && (h.team.name || e.mode === 'lf_4aus5' || e.mode === 'lf_4aus4')">
      <v-col cols="6"><b>Wettkampfdatum:</b> {{ e.startDate | dateformat('DD.MM.YYYY') }}</v-col>
      <v-col cols="6"><b>Wettkampfort:</b> {{ e.venue ? e.venue.address.addressLocality : '' }}</v-col>
      <v-col cols="6"><b>Heimmannschaft:</b> {{ h.team.name }}</v-col>
      <v-col cols="6"><b>Gastmannschaft:</b> {{ g.team.name }}</v-col>
      <v-col cols="6"><b>Mannschaftsführer Heim:</b> {{ heimv | person }}</v-col>
      <v-col cols="6"><b>Mannschaftsführer Gast:</b> {{ gastv | person }}</v-col>
      <v-col cols="6">
        <base-edit-dialog
          :value="info.wettkampfleiter"
          label="Wettkampfleiter"
          id="null"
          textfield
          @input="({value}) => s('wettkampfleiter', value)"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="6">
        <!--base-edit-dialog
          :value="info.hygienebeauftragter"
          label="Hygienebeauftragter"
          id="null"
          textfield
          @input="({value}) => s('hygienebeauftragter', value)"
          :disabled="disabled"
        /-->
      </v-col>
      <v-col cols="6"><b>Kampfrichter E1 (Oberkampfrichter):</b> {{ judge(e, 'e1') | person }}</v-col>
      <v-col cols="6"><b>Kampfrichter E2 (neutral):</b> {{ judge(e, 'e2') | person }}</v-col>
      <v-col cols="6"><b>Kampfrichter D1 (Heim):</b> {{ judge(e, 'dh') | person }}</v-col>
      <v-col cols="6"><b>Kampfrichter D2 (Gast):</b> {{ judge(e, 'dg') | person }}</v-col>
      <v-col cols="6"><b>Kampfrichter E3 (Heim):</b> {{ judge(e, 'e3') | person }}</v-col>
      <v-col cols="6"><b>Kampfrichter E4 (Gast):</b> {{ judge(e, 'e4') | person }}</v-col>
      <v-col cols="6"><b>Ergebnis Heim:</b> {{ mannschaftgesamtergebnis(e, r, h.team._id) | float2_0 }}</v-col>
      <v-col cols="6"><b>Ergebnis Gast:</b> {{ mannschaftgesamtergebnis(e, r, g.team._id) | float2_0 }}</v-col>
      <v-col cols="6"><b>Tabellenpunkte Heim:</b> {{ mannschaftgesamtergebnistp(e, r, h.team._id) | int_0_or.5 }}</v-col>
      <v-col cols="6"><b>Tabellenpunkte Gast:</b> {{ mannschaftgesamtergebnistp(e, r, g.team._id) | int_0_or.5 }}</v-col>
      <v-col cols="6"><b>Gerätepunkte Heim:</b> {{ mannschaftgesamtergebnisgp(e, r, df, h.team._id) | int_0_or.5 }}</v-col>
      <v-col cols="6"><b>Gerätepunkte Gast:</b> {{ mannschaftgesamtergebnisgp(e, r, df, g.team._id) | int_0_or.5 }}</v-col>
      <v-col cols="6">
        <base-edit-dialog
          :value="info.zuschauer"
          label="Anzahl Zuschauer"
          id="null"
          textfield
          @input="({value}) => s('zuschauer', isNaN(parseInt(value)) ? null : parseInt(value))"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="6"></v-col>
      <v-col cols="6"><b>Wettkampfbeginn:</b> {{ e.startDate | dateformat('HH:mm') }}</v-col>
      <v-col cols="6">
        <base-edit-dialog
          :value="info.ende"
          label="Wettkampfende (Format: HH:MM)"
          id="null"
          textfield
          @input="({value}) => s('ende', parseZeit(value))"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12">
        <base-edit-dialog
          :value="info.dauer"
          label="Begründung, falls Wettkampf länger als 2,5h dauert"
          id="null"
          textarea
          @input="({value}) => s('dauer', value)"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12">
        <base-edit-dialog
          :value="info.special"
          label="Besondere Vorkommnisse"
          id="null"
          textarea
          @input="({value}) => s('special', value)"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" v-if="!isReady">
        <h2 style="color:red;">Zuschauerzahl und Wettkampfende<template v-if="dauer > 2.6"> und Begründung für länger als 2,5h</template> muss ausgefüllt werden bevor der Wettkampfbericht unterschrieben oder heruntergeladen werden kann!</h2>
      </v-col>
      <v-col cols="12" v-else>
        <h2>Unterschriften direkt im Browser:</h2>
        <p>
          Die Verantwortlichen können direkt hier im Browser unterschreiben (bei einem Tablet mit Stift). Alternativ lässt sich
          der Wettkampfbericht auch ohne Unterschriften herunterladen, ausdrucken und unterschreiben. Dieser muss dann manuell
          abfotografiert und unter "Abschluss" wieder hochgeladen werden!
        </p>
        <p style="color:red;">
          Wichtig: Die Unterschriften müssen mit dem entsprechenden Button gespeichert werden! (Die Buttons verschwinden dann
          und es lässt sich nichts mehr daran ändern.) Sobald mindestens eine Unterschrift getätigt und gespeichert wurde lässt
          sich an den Eingabefeldern oben nichts mehr ändern!
        </p>
        <Unterschrift type="Oberkampfrichter" :name="judge(e, 'e1') | person" :image="getunterschrift('unterschrift_nach_ok.png')" @save="(d) => saveunterschrift('unterschrift_nach_ok.png', d)" :disabled="abgeschlossen" />
        <Unterschrift type="neutraler Kampfrichter" :name="judge(e, 'e2') | person" :image="getunterschrift('unterschrift_nach_neutral.png')" @save="(d) => saveunterschrift('unterschrift_nach_neutral.png', d)" :disabled="abgeschlossen" />
        <Unterschrift type="D-Kampfrichter Heim" :name="judge(e, 'dh') | person" :image="getunterschrift('unterschrift_nach_dheim.png')" @save="(d) => saveunterschrift('unterschrift_nach_dheim.png', d)" :disabled="abgeschlossen" />
        <Unterschrift type="D-Kampfrichter Gast" :name="judge(e, 'dg') | person" :image="getunterschrift('unterschrift_nach_dgast.png')" @save="(d) => saveunterschrift('unterschrift_nach_dgast.png', d)" :disabled="abgeschlossen" />
        <Unterschrift type="E-Kampfrichter Heim" :name="judge(e, 'e3') | person" :image="getunterschrift('unterschrift_nach_eheim.png')" @save="(d) => saveunterschrift('unterschrift_nach_eheim.png', d)" :disabled="abgeschlossen" />
        <Unterschrift type="E-Kampfrichter Gast" :name="judge(e, 'e4') | person" :image="getunterschrift('unterschrift_nach_egast.png')" @save="(d) => saveunterschrift('unterschrift_nach_egast.png', d)" :disabled="abgeschlossen" />
        <Unterschrift type="Mannschaftsverantwortlicher Heim" :name="heimv | person" :image="getunterschrift('unterschrift_nach_heim.png')" @save="(d) => saveunterschrift('unterschrift_nach_heim.png', d)" :disabled="abgeschlossen" />
        <Unterschrift type="Mannschaftsverantwortlicher Gast" :name="gastv | person" :image="getunterschrift('unterschrift_nach_gast.png')" @save="(d) => saveunterschrift('unterschrift_nach_gast.png', d)" :disabled="abgeschlossen" />
        <v-btn color="red" @click="reset()" v-if="!abgeschlossen">Unterschriften zurücksetzen</v-btn>
      </v-col>
      <v-col cols="12" v-if="isReady">
        <v-btn block @click="down1">Download WK-Bericht nach WK</v-btn>
        <v-btn block @click="ergebnis(e, df, r, true)">Download Ergebnis-PDF</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { usePdf } from '../../../plugins/pdf'
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'
import { GRAPHQLlocal, GRAPHQLserver } from '@/env'
import { useCalc } from '../../../plugins/calc'

export default {
  name: 'wkbericht',

  components: {
    Unterschrift: () => import('../general/unterschrift')
  },

  setup (props, context) {
    return {
      ...usePdf(context),
      ...useGraphQL(context),
      ...useCalc()
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    h () {
      return this.e?.teams?.find(t => t.home) || { team: {} }
    },
    g () {
      return this.e?.teams?.find(t => !t.home) || { team: {} }
    },
    heimv () {
      return this.h?.trainer?.find(t => t.order === 1)?.person || {}
    },
    gastv () {
      return this.g?.trainer?.find(t => t.order === 1)?.person || {}
    },
    info () {
      return this.e.info || {}
    },
    imageBase () {
      if (!GRAPHQLlocal) return '/uploads/'

      const base = GRAPHQLserver.match(/(wss?):\/\/([^/]*)/)
      return `http${base[1] === 'wss' ? 's' : ''}://${base[2]}/uploads/`
    },
    isReady () {
      return !!this.info.zuschauer && !!this.info.ende && (this.dauer <= 2.6 || !!this.info.dauer)
    },
    abgeschlossen () {
      return this.e.status === 'EventFinished'
    },
    disabled () {
      return !!this.getunterschrift('unterschrift_nach_ok.png') ||
        !!this.getunterschrift('unterschrift_nach_neutral.png') ||
        !!this.getunterschrift('unterschrift_nach_eheim.png') ||
        !!this.getunterschrift('unterschrift_nach_egast.png') ||
        !!this.getunterschrift('unterschrift_nach_dheim.png') ||
        !!this.getunterschrift('unterschrift_nach_dgast.png') ||
        !!this.getunterschrift('unterschrift_nach_heim.png') ||
        !!this.getunterschrift('unterschrift_nach_gast.png') ||
        this.abgeschlossen
    },
    dauer () {
      if (!this.info.ende) return 0

      const start = this.e.startDate.match(/.* ([0-9]{2}):([0-9]{2})/)
      const ende = this.info.ende.match(/([0-9]{2}):([0-9]{2})/)

      const m = (ende[2] - start[2]) / 60
      const h = ende[1] - start[1] + m

      return h
    }
  },

  methods: {
    parseZeit (value) {
      const tmp = value.match(/[^0-9]*([0-9]{1,2}).([0-9]{2}).*/)
      if (!tmp || tmp.length !== 3) {
        if (value !== '') this.$store.commit('OPEN_SNACKBAR', 'Wettkampfende bitte in der Form HH:MM (z.B. 18:30) eingeben!')
        return null
      }
      return `${tmp[1]}:${tmp[2]}`
    },
    down1 () {
      this.wkbericht1(this.e, this.r, this.df, this.info.wettkampfleiter || '', this.info.hygienebeauftragter || '', this.info.ende || '', this.info.zuschauer || '', this.info.dauer || '', this.info.special || '')
    },
    s (field, value) {
      this.mutate({
        mutation: gql`mutation($id: UUID!,
          $ersthelfer: String, $unfallhilfe: Boolean, $rettungsdienst: String, $bemerkung: String,
          $geraetenorm: String, $wettkampfleiter: String, $hygienebeauftragter: String,
          $zuschauer: Int, $ende: String, $dauer: String, $special: String) {
          StbM2021WkInfoUpdate(id: $id,
            ersthelfer: $ersthelfer, unfallhilfe: $unfallhilfe, rettungsdienst: $rettungsdienst, bemerkung: $bemerkung,
            geraetenorm: $geraetenorm, wettkampfleiter: $wettkampfleiter, hygienebeauftragter: $hygienebeauftragter,
            zuschauer: $zuschauer, ende: $ende, dauer: $dauer, special: $special) { _id }
        }`,
        variables: {
          id: this.id,
          [field]: value
        }
      })
    },
    saveunterschrift (filename, filedata) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $filedata: String!, $filename: String!, $mimetype: String!) {
          StbM2021WkInfoUpdateAttachmentAdd(id: $id, filedata: $filedata, filename: $filename, mimetype: $mimetype) {
            info { attachments { _id filename mimetype } }
          }
        }`,
        variables: {
          id: this.id,
          filedata,
          filename,
          mimetype: 'image/png'
        }
      })
    },
    getunterschrift (filename) {
      const a = this.info.attachments?.find(a => a.filename === filename)
      return a ? this.imageBase + a._id : null
    },
    async reset () {
      const del = async (file) => {
        const a = this.info.attachments?.find(a => a.filename === file)?._id
        if (!a) return

        this.mutate({
          mutation: gql`mutation($id: UUID!, $file: UUID!) {
            StbM2021WkInfoUpdateAttachmentDelete(id: $id, file: $file) { info { attachments { _id filename mimetype } } }
          }`,
          variables: {
            id: this.id,
            file: a
          }
        })
      }

      if (await this.$root.$children[0].$refs.confirm.open('Wirklich zurücksetzen?', 'Es müssen alle Unterschriften anschließend erneut eingegeben werden!')) {
        del('unterschrift_nach_ok.png')
        del('unterschrift_nach_neutral.png')
        del('unterschrift_nach_eheim.png')
        del('unterschrift_nach_egast.png')
        del('unterschrift_nach_dheim.png')
        del('unterschrift_nach_dgast.png')
        del('unterschrift_nach_heim.png')
        del('unterschrift_nach_gast.png')
      }
    }
  }
}
</script>

<style scoped>

</style>
